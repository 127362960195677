import React, { useState } from "react";
import Header from "../components/header";
import Card from "./Card";
import VectorWhite from "../assests/icons/Vector-white.svg";
import ArrowRightBlack from "../assests/icons/ArrowRightBlack.svg";
import { Dialog } from "@headlessui/react";
import EmailSection from "./EmailSection";

const getDifficultyLabel = (level) => {
  switch (level) {
    case 1:
      return "Easy";
    case 2:
      return "Moderate";
    case 3:
      return "Difficult";
    case 4:
      return "Challenging";
    default:
      return "Challenging";
  }
};

const Plan = ({ plans, dialogText, profession, selectedAge , selectedProfessionalSummary}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCards, setSelectedCards] = useState(() =>
    plans.map((_, index) => index)
  ); // Manage selected cards

  console.log(plans);

  const toggleCuratingPlan = () => {
    selectedCards?.length > 0 ? setIsOpen(true) : alert("Please select a plan");
  };

  const toggleCardSelection = (index) => {
    setSelectedCards((prev) => {
      const isAlreadySelected = prev.includes(index);

      return isAlreadySelected
        ? prev.filter((i) => i !== index) // Deselect
        : [...prev, index]; // Select
    });
  };

  console.log(selectedCards);

  return (
    <div className="relative bg-custom-gray">
      <Header />
      <div className="bg-custom-gray p-6 flex justify-center items-center  h-auto sm:h-full frame-border-gradient">
        <div className="Plans-dialogues mt-[20px] sm:mt-[60px] lg:mt-[120px] mb-[20px] sm:mb-[60px] lg:mb-[120px]">
          <div className="h-full p-4 sm:p-8 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-[69px] inline-flex">
            <div className="self-stretch h-full flex-col justify-start items-start gap-3 flex">
              <div className="w-full">
                <div className="flex-col justify-center items-center gap-3 inline-flex w-full">
                  <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">
                    Here are the plans for you
                  </div>
                  <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">
                    Discover your customized habit plans tailored specifically
                    to you.
                  </div>
                  <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">
                    {selectedProfessionalSummary}
                  </div>
                </div>
              </div>

              <div className="card-section w-full">
                <div className="w-full pt-8 flex-col justify-start items-center gap-[3ren] inline-flex">
                  <div className="w-full max-w-[1076px] card-container">
                    <div className="self-stretch flex-col justify-start items-center gap-4 flex ">
                      <div className="self-stretch gap-4 grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {plans.map((card, index) => (
                          <Card
                            key={index}
                            id={index}
                            title={card.name}
                            interestedCount={"100+"}
                            planDuration={`${card.days_per_week} day a week`}
                            details={card.plan_detail}
                            score={`${card.Score} Score`}
                            difficultyLevel={getDifficultyLabel(
                              card.difficulty_level
                            )}
                            selectedAge={selectedAge}
                            profession={profession}
                            dialogText={dialogText}
                            selected={selectedCards.includes(index)} // Pass selected state
                            onCardSelect={() => toggleCardSelection(index)} // Handle click
                            selectedCards={selectedCards}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full pt-[56px]">
                <div className="flex justify-between items-center flex-col-reverse sm:flex-row w-full gap-4">
                  <div className="justify-between items-center gap-4 flex w-full sm:w-auto">
                    <div className="justify-start items-center gap-4 flex flex-col sm:flex-row w-full sm:w-auto">
                      <button
                        onClick={toggleCuratingPlan}
                        className="text-white text-base font-normal font-['Outfit'] px-5 py-2 rounded-[999px] border border-[#4fd049] justify-center items-center gap-3 flex w-full sm:w-auto createPlanManually"
                      >
                        Create Plan Manually
                      </button>
                      <button
                        onClick={toggleCuratingPlan}
                        className="px-5 text-white text-base font-normal font-['Outfit'] py-2 bg-[#284725] rounded-[999px] justify-center items-center gap-3 flex w-full sm:w-auto generatemorePlans"
                      >
                        <div className="w-[18.32px]">
                          <img
                            src={VectorWhite}
                            alt="Vector Icon"
                            className="w-full h-full"
                          />
                        </div>
                        Generate More Plans
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={toggleCuratingPlan}
                    className="w-[304px] text-[#06030b] text-sm font-normal font-['Outfit'] px-5 py-2 bg-gradient-to-r from-[#4fd049] to-[#cbc435] rounded-[999px] justify-center items-center gap-3 flex w-full sm:w-auto continuewiththePlan"
                  >
                    <div className="w-[18.32px]">
                      <img
                        src={VectorWhite}
                        alt="Vector Icon"
                        className="w-full h-full"
                      />
                    </div>
                    Continue with the Plan
                    <div className="w-4 h-4 relative">
                      <img
                        src={ArrowRightBlack}
                        alt="Arrow Right Black Icon"
                        className="w-full h-full"
                      />
                    </div>
                  </button>
                  {/* Full-Screen Modal */}
                  <Dialog
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    className="relative z-50"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center h-full">
                      <Dialog.Panel className="bg-custom-gray w-full h-full overflow-auto shadow-lg relative">
                        {/* Close Button */}
                        <button
                          onClick={() => setIsOpen(false)}
                          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                        >
                          ✕
                        </button>

                        {/* CuratingPlan Component */}
                        <EmailSection
                          selectedAge={selectedAge}
                          profession={profession}
                          dialogText={dialogText}
                        />
                      </Dialog.Panel>
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
