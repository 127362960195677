import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import HomePage from './pages/home';
import Mentor from './pages/mentor';
import SignUpToday from './pages/SignUpToday';
import Footer from './components/Footer';
import FAQPage from './pages/FAQPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import ScrollToTop from "./ScrollToTop";

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Header />
        {/* Ensures page scrolls to the top on route changes */}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<SignUpToday />} />
          <Route path="/lp/goal" element={<HomePage />} />
          <Route path="/lp/mentor" element={<Mentor />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/termsofservice" element={<Terms />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
};

export default App;
