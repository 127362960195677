import React, { useState } from "react";
import Header from "./header";
import DropdownProfession from "./DropdownProfession";
import CuratingPlanAfterAge from "./CuratingPlanAfterAge";
import Vector from "../assests/icons/Vector.svg";

const FineTuning = ({ dialogText }) => {
  const [profession, setProfession] = useState(null);
  const [selectedAge, setSelectedAge] = useState(null);
  const [showCuratingPlan, setShowCuratingPlan] = useState(false);

  const handleAgeSelect = (ageRange) => {
    setSelectedAge(ageRange);
  };

  const handleSubmit = () => {
    setShowCuratingPlan(true);
  };

  if (showCuratingPlan) {
    return (
      <CuratingPlanAfterAge
        selectedAge={selectedAge}
        profession={profession}
        dialogText={dialogText}
      />
    );
  }

  return (
    <div className="relative bg-custom-gray">
      <Header />
      <div className="w-max-[1076px] mx-auto bg-custom-gray p-6 flex justify-center items-center frame-border-gradient h-auto sm:h-full padding-dialogue">
        <div className="Plans-dialogue mt-[20px] sm:mt-[60px] lg:mt-[120px]">
          <div className="p-4 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center inline-flex gap-[30px] sm:gap-[69px] sm:p-8">
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">
                Fine-tune your plans
              </div>
              <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">
                This additional information will be useful for our AI to
                fine-tune and create better personalized plans for you.
              </div>
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-10 flex">
              <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch text-[#aeadb2] text-lg font-light font-['Outfit']">
                  What is your Age group?
                </div>
                <div className="self-stretch justify-start items-center gap-2 inline-flex flex-col sm:flex-row">
                  {[
                    "under 12 years",
                    "13 to 18 years",
                    "19 to 24 years",
                    "25 to 44 years",
                    "45 to 64 years",
                    "65+ years",
                  ].map((ageRange) => (
                    <button
                      key={ageRange}
                      onClick={() => handleAgeSelect(ageRange)}
                      className={`px-4 py-2 rounded-[999px] border ${
                        selectedAge === ageRange
                          ? "border-[#4fd049] bg-[#7dcc78]"
                          : "border-[#7dcc78]"
                      } justify-center items-center gap-3 flex w-full sm:w-auto`}
                    >
                      <div className="text-white text-sm font-normal font-['Outfit']">
                        {ageRange}
                      </div>
                    </button>
                  ))}
                </div>
                {selectedAge && (
                  <div className="text-[#aeadb2] text-sm font-light font-['Outfit'] mt-2">
                    Selected Age Range: {selectedAge}
                  </div>
                )}
              </div>
              <div className="self-stretch h-[103px] flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch text-[#aeadb2] text-lg font-light font-['Outfit']">
                  What is your profession?
                </div>
                <DropdownProfession
                  profession={profession}
                  setProfession={setProfession}
                />
              </div>
            </div>
            <div className="self-stretch justify-end items-center gap-8 inline-flex flex-col-reverse sm:flex-row">
              {/* <div onClick={handleSkip} className="text-[#65636a] text-base font-normal font-['Outfit'] cursor-pointer">
                Skip this Step
              </div> */}
              <button
                onClick={handleSubmit}
                className="w-full sm:w-auto px-5 py-2 rounded-[999px] justify-center items-center gap-3 flex cursor-pointer text-white text-base font-normal font-['Outfit'] createPlan border-gradient"
              >
                <span>
                <div className="w-[18.32px]">
                  <img
                    src={Vector}
                    alt="Vector Icon"
                    className="w-full h-full"
                  />
                </div>
                  Create Plans
                  </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FineTuning;
