import React, { useState } from "react";
import Logo from "../assests/icons/Layer_1.svg";
import { Menu, X } from "react-feather"; // Icons for mobile menu toggle
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="px-4 py-3 border-b bg-[rgba(63,60,69,0.32)] shadow-[0px_4px_21.3px_rgba(0,0,0,0)] backdrop-blur-[28px] border-gray-700 shadow border-b border-[#302d38] backdrop-blur-[56px] flex flex-col md:flex-row md:justify-between md:items-center absolute sm:sticky sm:top-0 sm:z-[999]">
      {/* Logo Section */}
      <div className="flex items-center gap-2">
        <div className="h-8 pl-[3.39px] pr-[2.39px] pt-1.5 pb-[3px] flex items-center">
          <RouterLink to="/">
            <img
              src={Logo}
              alt="TrackHabit Logo"
              className="w-[26.22px] h-[23px]"
            />
          </RouterLink>
        </div>
        <RouterLink to="/">
          <span className="text-white text-xl font-normal font-['Outfit']">
            TrackHabit
          </span>
        </RouterLink>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden md:flex gap-8">
        {[
          { label: "Features", isScroll: true, id: "features" },
          { label: "Start with Goal", url: "/lp/goal" },
          { label: "Follow Celebrity Routine", url: "/lp/mentor" },
        ].map(({ label, isScroll, id, url }, index) =>
          isScroll ? (
            <ScrollLink
              key={index}
              to={id}
              smooth={true}
              duration={500}
              offset={-70} 
              className="text-[#aeadb2] text-sm font-normal font-['Outfit'] hover:text-blue-700 dark:hover:text-blue-500 p-[9px] hover:bg-[#50D049] hover:rounded-[32px] hover:!text-white cursor-pointer"
            >
              {label}
            </ScrollLink>
          ) : (
            <RouterLink
              key={index}
              to={url}
              className="text-[#aeadb2] text-sm font-normal font-['Outfit'] hover:text-blue-700 dark:hover:text-blue-500 p-[9px] hover:bg-[#50D049] hover:rounded-[32px] hover:!text-white"
            >
              {label}
            </RouterLink>
          )
        )}
      </nav>

      {/* Mobile Menu Toggle */}
      <div className="md:hidden">
        <button
          onClick={toggleMobileMenu}
          className="text-white absolute top-[15px] right-[20px]"
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="inset-0 flex flex-col items-center gap-4 pt-5 text-white z-10 md:hidden">
          {[
            { label: "Features", isScroll: true, id: "features" },
            { label: "Start with Goal", url: "/lp/goal" },
            { label: "Follow Celebrity Routine", url: "/lp/mentor" },
          ].map(({ label, isScroll, id, url }, index) =>
            isScroll ? (
              <ScrollLink
                key={index}
                to={id}
                smooth={true}
                duration={500}
                offset={-70} // Adjust offset if needed
                onClick={toggleMobileMenu}
                className="text-lg font-['Outfit'] hover:text-blue-500 cursor-pointer"
              >
                {label}
              </ScrollLink>
            ) : (
              <RouterLink
                key={index}
                to={url}
                onClick={toggleMobileMenu}
                className="text-lg font-['Outfit'] hover:text-blue-500"
              >
                {label}
              </RouterLink>
            )
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
