import React from "react";
import Logo from "../assests/icons/Layer_1.svg";
import X from "../assests/icons/XLogo.svg";
import FBLogo from "../assests/icons/FacebookLogo.svg";
import Insta from "../assests/icons/InstagramLogo.svg";
import Linkedin from "../assests/icons/LinkedinLogo.svg";
import PintrestLogo from "../assests/icons/PinterestLogo.svg";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full px-4 py-4 sm:py-10 bg-[#3f3c45]/30 flex-col justify-start items-start gap-6 inline-flex ">
      <div className="w-full flex flex-col justify-start items-start gap-6 inline-flex">
        <div className="self-stretch flex justify-start items-start gap-12 inline-flex hidden lg:flex">
          <div className="grow shrink basis-0 self-stretch flex-col justify-between items-start inline-flex">
            <div className="self-stretch justify-start items-start gap-[4.87px] inline-flex">
              <div className="w-[181.09px] h-10 justify-center items-start gap-[6.09px] flex">
                <div className="h-10 pl-[4.23px] pr-[2.99px] pt-[7.50px] pb-[3.75px] justify-center items-center inline-flex">
                  <div className="w-[32.78px] h-[28.75px] relative flex-col justify-start items-start flex">
                    <Link to="/">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="text-white text-[25px] font-normal  font-['Outfit']">
                  <Link to="/">TrackHabit</Link>
                </div>
              </div>
            </div>
            <div className="self-stretch text-[#d7e4dd]/60 text-xs font-medium font-['Plus Jakarta Sans'] leading-tight">
              © 2024 trackhabit. All Rights Reserved
            </div>
          </div>
          {/* <div className="w-[200px] flex-col justify-center items-start gap-6 inline-flex">
          <div className="self-stretch text-[#d7e4dd] text-base font-bold font-['Outfit'] leading-tight">Product</div>
          <div className="self-stretch h-[104px] flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
              [Insert Link]
            </div>
            <div className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
              [Insert Link]
            </div>
            <div className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
              [Insert Link]
            </div>
            <div className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
              [Insert Link]
            </div>
          </div>
        </div> */}
          {/* <div className="w-[200px] flex-col justify-center items-start gap-6 inline-flex">
          <div className="self-stretch text-[#d7e4dd] text-base font-bold font-['Outfit'] leading-tight">Company</div>
          <div className="self-stretch h-[104px] flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
              [Insert Link]
            </div>
            <div className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
              [Insert Link]
            </div>
            <div className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
              [Insert Link]
            </div>
            <div className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
              [Insert Link]
            </div>
          </div>
        </div> */}
          <div className="w-[200px] flex-col justify-center items-start gap-6 inline-flex">
            <div className="self-stretch text-[#d7e4dd] text-base font-bold font-['Outfit'] leading-tight">
              Support
            </div>
            <div className="self-stretch h-[104px] flex-col justify-start items-start gap-2 flex">
              <button className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
                <Link to="/faq" className="text-[#d7e4dd]/60 " onClick={() => window.scrollTo(0, 0)}>
                  FAQ
                </Link>
              </button>
              <button className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
                Contact
              </button>
              <button className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
                <Link to="/termsofservice" className="text-[#d7e4dd]/60 " onClick={() => window.scrollTo(0, 0)}>
                  Terms of Services
                </Link>
              </button>
              <button className="self-stretch text-[#d7e4dd]/60 text-[15px] font-medium font-['Plus Jakarta Sans'] leading-tight text-left">
                <Link to="/privacy" className="text-[#d7e4dd]/60 " onClick={() => window.scrollTo(0, 0)}>
                  Privacy Policy
                </Link>
              </button>
            </div>
          </div>
          <div className="w-[200px] flex-col justify-center items-start gap-6 inline-flex">
            <div className="self-stretch text-[#d7e4dd] text-[15px] font-bold font-['Plus Jakarta Sans'] leading-tight">
              Follow Us
            </div>
            <div className="justify-start items-center gap-2 inline-flex">
              <img className="w-6 h-6 relative" src={X} alt="X logo" />
              <a
                href="https://www.facebook.com/people/Trackhabitio/61566837763498/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-6 h-6 relative"
                  src={FBLogo}
                  alt="facebook logo"
                />
              </a>

              <img className="w-6 h-6 relative" src={Insta} alt="insta logo" />
              <a
                href="https://www.linkedin.com/company/trackhabit-io?_l=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-6 h-6 relative"
                  src={Linkedin}
                  alt="linkedin logo"
                />
              </a>

              <img
                className="w-6 h-6 relative"
                src={PintrestLogo}
                alt="Pinterest logo"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex-col justify-start items-start gap-6 inline-flex block lg:hidden">
        <div className="flex flex-col items-start gap-4">
          <div className="flex items-center gap-2">
            <Link to="/">
              <img
                src={Logo}
                alt="TrackHabit Logo"
                className="w-[26.22px] h-[23px]"
              />
            </Link>
            <div className="text-white text-xl font-semibold">
              <Link to="/">TrackHabit</Link>
            </div>
          </div>
        </div>
        {/* <div className="py-2 border-b border-[#d7e4dd]/40 w-full">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full text-left text-white font-bold text-base py-2">
                  Product
                  {open ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 011.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg> // Down arrow
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01-.02-1.06L10.94 10 7.19 6.27a.75.75 0 011.06-1.06l4 4a.75.75 0 010 1.06l-4 4a.75.75 0 01-1.06.02z"
                        clipRule="evenodd"
                      />
                    </svg> // Right arrow
                  )}
                </Disclosure.Button>
                <Disclosure.Panel className="text-[#d7e4dd]/60 text-sm flex flex-col gap-2">
                  <div>[Insert Link]</div>
                  <div>[Insert Link]</div>
                  <div>[Insert Link]</div>
                  <div>[Insert Link]</div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <div className="py-2 border-b border-[#d7e4dd]/40 w-full">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full text-left text-white font-bold text-base py-2 flex justify-between">
                  Company
                  {open ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 011.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg> // Down arrow
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01-.02-1.06L10.94 10 7.19 6.27a.75.75 0 011.06-1.06l4 4a.75.75 0 010 1.06l-4 4a.75.75 0 01-1.06.02z"
                        clipRule="evenodd"
                      />
                    </svg> // Right arrow
                  )}
                </Disclosure.Button>
                <Disclosure.Panel className="text-[#d7e4dd]/60 text-sm flex flex-col gap-2 ">
                  <div>[Insert Link]</div>
                  <div>[Insert Link]</div>
                  <div>[Insert Link]</div>
                  <div>[Insert Link]</div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div> */}

        <div className="py-2 border-b border-[#d7e4dd]/40 w-full">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="w-full text-left text-white font-bold text-base flex justify-between">
                  Support
                  {open ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 011.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg> // Down arrow
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01-.02-1.06L10.94 10 7.19 6.27a.75.75 0 011.06-1.06l4 4a.75.75 0 010 1.06l-4 4a.75.75 0 01-1.06.02z"
                        clipRule="evenodd"
                      />
                    </svg> // Right arrow
                  )}
                </Disclosure.Button>
                <Disclosure.Panel className="text-[#d7e4dd]/60 text-sm flex py-6 flex-col gap-2 ">
                  <Link to="/faq" className="text-[#d7e4dd]/60 text-sm">
                    FAQ
                  </Link>

                  <div>Contact</div>
                  <Link
                    to="/termsofservice"
                    className="text-[#d7e4dd]/60 text-sm"
                  >
                    Terms of Services
                  </Link>
                  <Link to="/privacy" className="text-[#d7e4dd]/60 text-sm">
                    Privacy Policy
                  </Link>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div className="text-white font-bold text-base">Follow Us</div>
          <div className="flex items-center gap-2 border-b border-[#d7e4dd]/40 pb-6">
            <img className="w-6 h-6" src={X} alt="X logo" />
            <a
              href="https://www.facebook.com/people/Trackhabitio/61566837763498/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-6 h-6 relative"
                src={FBLogo}
                alt="facebook logo"
              />
            </a>

            <img className="w-6 h-6 relative" src={Insta} alt="insta logo" />
            <a
              href="https://www.linkedin.com/company/trackhabit-io?_l=en_US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-6 h-6 relative"
                src={Linkedin}
                alt="linkedin logo"
              />
            </a>
            <img className="w-6 h-6" src={Insta} alt="Instagram logo" />
            <img className="w-6 h-6" src={PintrestLogo} alt="Pinterest logo" />
          </div>
          <div className="text-[#d7e4dd]/60 text-sm">
            © 2024 TrackHabit. All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
