import React from "react";
import Card from "../components/Card";
import InfoCard1 from "../components/InfoCard1";
import InfoCard2 from "../components/InfoCard2";
import frame712 from "../assests/images/Frame 712.png";
import frame711 from "../assests/images/Frame 711.png";
import frame713 from "../assests/images/Frame 713.png";
import frame714 from "../assests/images/Frame 714.png";
import CheckCircle from "../assests/icons/CheckCircle.svg";
import ArrowRightWhite from "../assests/icons/ArrowRight.svg";
import ProfileImage from "../assests/images/Profile image.png";



const cardsData = [
    {
      title: "Swimming Lessons",
      planDuration: "4 days a week",
      details:
        "Take 1 hour swimming lessons every Monday, Wednesday and Friday for 4 months",
      difficultyLevel: "Difficult",
      score: "30 Score",
    },
    {
      title: "Outdoor Running Challenge",
      planDuration: "2 days a week",
      details: "Start with light jog",
      difficultyLevel: "Challenging",
      score: "83 Score",
    },
    {
      title: "Daily Journaling Habit",
      planDuration: "6 days a week",
      details: "Write thoughts and reflections",
      difficultyLevel: "Easy",
      score: "90 Score",
    },
    {
      title: "Digital Detox Challenge",
      planDuration: "1 days a week",
      details: "Limit screen time and enjoy nature",
      difficultyLevel: "Moderate",
      score: "60 Score",
    },
  ];
  
  const firstCardData = {
    head: "Let AI Take Care of Your Habits, So You Can Take Care of Yourself",
    text: "Let AI manage your habits, giving you more time to focus on yourself. With smart insights and tailored suggestions, our app keeps you on track, so you can achieve your goals effortlessly.",
    image: frame712,
  };
  const secondCardData = {
    head: "Measure Your Progress with the Power of AI-Driven Insights",
    text: "AI-Driven Goal Tracking offers personalized insights by analyzing your emotional patterns, helping you identify triggers and optimize routines for improved well-being. Turn emotional awareness into actionable growth with tailored real-time insights.",
    image: frame711,
  };
  const thirdCardData = {
    head: "Enhanced Goals Tracking for Tailored Insights",
    text: "Track your progress with AI-driven insights that offer real-time feedback and personalized tips. Turn daily habits into lasting achievements with smarter, data-backed guidance.",
    image: frame713,
  };
  
  const forthCardData = {
    head: "Journal, reflect and look back",
    text: "Track your progress with our AI-powered app. Journal, reflect, and uncover patterns to understand what drives your success and where to improve. Learn from your habits to build a better future.",
    image: frame714,
  };


  const HomeComponet = () =>{
    return(
    <div>
              <div className="card-section">
        <div className="w-full bg-custom-gray px-8 sm:px-16 pt-8 pb-[4.5rem] flex-col justify-start items-center gap-[3rem] inline-flex">
          <div className="self-stretch text-center text-white text-3xl sm:text-4xl font-light font-['Outfit']">
            See what others are planning
          </div>
          <div className="w-full max-w-[1076px] card-container">
            <div className="self-stretch flex-col justify-start items-center gap-4 flex">
              <div className="self-stretch gap-4 grid grid-cols-1 sm:grid-cols-2 gap-6">
                {cardsData.map((card, index) => (
                  <Card
                    key={index}
                    title={card.title}
                    interestedCount={card.interestedCount}
                    planDuration={card.planDuration}
                    details={card.details}
                    difficultyLevel={card.difficultyLevel}
                    score={card.score}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="powered-habits" id="features">
        <div className="w-full bg-custom-gray px-8 sm:px-16 pt-8 pb-[4.5rem] flex-col justify-start items-center gap-[3rem] inline-flex">
          <div className="self-stretch flex-col justify-start items-center gap-4 flex">
            <div className="self-stretch text-center text-white text-3xl sm:text-4xl font-light font-['Outfit']">
              AI-powered features for Habits
            </div>
            <div className="px-5 py-2 rounded-[999px] border border-[#302d38] justify-center items-center gap-3 inline-flex">
              <div className="text-[#7dcc78] text-base font-light font-['Outfit'] hero-text-gradient">
                Try 30+ Ai Habit Tracking features today
              </div>
              <div className="w-4 h-4">
                <img src={ArrowRightWhite} alt="arrow right" />
              </div>
            </div>
          </div>
          <div className="self-stretch flex-col justify-start items-center gap-4 flex">
            <div className="w-full max-w-[1076px] mx-auto self-stretch justify-center items-center inline-flex flex-col gap-4 sm:gap-8 sm:inline-flex sm:flex-row sm:gap-4">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 sm:gap-8 inline-flex">
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Recurring AI events
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Flexible time holds
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Smart time windows (for scheduling)
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Auto-rescheduling for conflicts (PTO, meetings, etc.)
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Priority ranking
                  </div>
                </div>
              </div>
              <div className="w-full grow shrink basis-0 flex-col justify-start items-start gap-4 sm:gap-8 inline-flex">
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Habit tracking analytics
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Work, personal, & family Habit templates
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Min-to-max event durations
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Slack status sync (during Habit events)
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      src={CheckCircle}
                      alt="Check Circle Icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="grow shrink basis-0 text-white text-[18px] sm:text-[22px] font-normal font-['Outfit']">
                    Custom privacy & visibility settings
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="info-card-main bg-custom-gray">
        <div className="info-card bg-custom-gray">
          {/* Render the first card separately */}
          <InfoCard1
            head={firstCardData.head}
            text={firstCardData.text}
            image={firstCardData.image}
          />
        </div>
        <div className="info-card bg-custom-gray">
          {/* Render the first card separately */}
          <InfoCard2
            head={secondCardData.head}
            text={secondCardData.text}
            image={secondCardData.image}
          />
        </div>
        <div className="info-card bg-custom-gray">
          {/* Render the first card separately */}
          <InfoCard1
            head={thirdCardData.head}
            text={thirdCardData.text}
            image={thirdCardData.image}
          />
        </div>
        <div className="info-card bg-custom-gray">
          {/* Render the first card separately */}
          <InfoCard2
            head={forthCardData.head}
            text={forthCardData.text}
            image={forthCardData.image}
          />
        </div>
      </div>

      <div className="testimonials bg-custom-gray w-full">
        <div className="px-8 sm:px-16 pt-8 pb-[4.5rem] flex-col justify-start items-center gap-[3rem] inline-flex w-full ">
          <div className="self-stretch text-center text-white text-3xl sm:text-4xl font-light font-['Outfit']">
            What people are saying about TrackHabit
          </div>
          <div className="w-full max-w-[1076px] mx-auto self-stretch grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Testimonial 1 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="Alex R."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    Alex R.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Effective and user-friendly
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  Since incorporating this AI habit tracker into my routine,
                  I've experienced notable improvements in achieving my goals.
                </div>
              </div>
            </div>

            {/* Testimonial 2 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="Sophia K."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    Sophia K.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Transformative tool
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  Using this AI habit tracker has been truly transformative for
                  me. The personalized insights have guided me towards
                  sustainable habits.
                </div>
              </div>
            </div>

            {/* Testimonial 3 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="Olivia L."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    Olivia L.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Empowering tool
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  This AI habit tracker has empowered me to take charge of my
                  habits like never before. The personalized insights have
                  guided me towards consistency and growth.
                </div>
              </div>
            </div>

            {/* Testimonial 4 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="Daniel W."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    Daniel W.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(5)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Consistency booster
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  This AI habit tracker has been a key factor in enhancing my
                  consistency and progress towards my goals. Highly recommended!
                </div>
              </div>
            </div>

            {/* Testimonial 5 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="David H."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    David H.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Inspiring progress
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  The insights from this AI habit tracker have inspired
                  remarkable progress in my goal-setting journey.
                </div>
              </div>
            </div>

            {/* Testimonial 6 */}
            <div className="px-4 pt-4 pb-6 bg-[#3f3c45]/30 rounded-3xl shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-6">
              <div className="self-stretch flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <img
                    className="w-[38.24px] h-[38.24px] rounded-full"
                    src={ProfileImage}
                    alt="David H."
                  />
                  <div className="text-white text-base font-normal font-['Outfit']">
                    David H.
                  </div>
                </div>
                <div className="flex">
                  {[...Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="w-5 h-5 relative text-yellow-400"
                    >
                      ★
                    </div>
                  ))}
                </div>
              </div>
              <div className="self-stretch pt-8">
                <div className="text-white text-xl font-normal font-['Outfit']">
                  Inspiring progress
                </div>
                <div className="text-[#aeadb2] text-base font-light font-['Outfit']">
                  The insights from this AI habit tracker have inspired
                  remarkable progress in my goal-setting journey.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

  export default HomeComponet;