import React, { useState } from "react";
import Header from "../components/header";
import SignUpToday from "../pages/SignUpToday";

const ThankNote = () => {
  const [showNewComponent, setShowNewComponent] = useState(false);

  if (showNewComponent) {
    return (
      <>
        <Header />
        <SignUpToday />
      </>
    );
  }

  const handleSubmit = () => {
    setShowNewComponent(true);
  };
  return (
    <div className="relative h-full  bg-custom-gray">
      <Header />
      <div className="w-max-[1076px] mx-auto bg-custom-gray h-auto sm:h-full p-6 flex justify-center items-center pt-120px sm:pt-auto frame-border-gradient">
        <div className="Plans-dialogue w-max-[1076px] mx-auto w-full">
          <div className="w-[100%] sm:h-[632px] px-4 sm:px-8 p-8 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-[72px] inline-flex">
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch text-center text-white text-[37px] font-light font-['Outfit'] leading-[64px]">
                Thank you for joining the waitlist
              </div>
              <div className="self-stretch text-center text-[#aeadb2] text-2xl font-light font-['Outfit']">
                We will reach out soon with exciting updates.
              </div>
            </div>
            <div className="self-stretch justify-center items-center gap-8 inline-flex">
              <button onClick={handleSubmit} className="w-[215px] text-white text-base font-normal font-['Outfit'] px-5 py-2 rounded-[999px] border border-[#4fd049] justify-center items-center gap-3 flex">
                  Back to Home Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankNote;
